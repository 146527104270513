
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    // const value = ref('');
    const formData = ref({
      number: "",
      per_hour_limit: "",
    });
    const registration = Yup.object().shape({
      number: Yup.number()
        .typeError("Please spacify a number")
        .required()
        .label("Number"),
      per_hour_limit: Yup.number()
        .integer()
        .typeError("Please spacify a number")
        .required()
        .label("Per Hour Limit"),
    });

    onMounted(() => {
      getNumberList(route.params.id);
      setCurrentPageBreadcrumbs("Edit Number", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
    });
    const getNumberList = (id) => {
      var request = {
        url: `number/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          console.log("data", data);
          formData.value = {
            number: data.data.phoneNumber,
            per_hour_limit: data.data.per_hour_limit,
          };
        }
      });
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      var id = route.params.id;
      var request = {
        url: `number/${id}`,
        data: {
          per_hour_limit: values.per_hour_limit,
        },
      };
      // Dummy delay
      //setTimeout(() => {
      // Send login request
      store
        .dispatch(Actions.PUT, request)
        .then((data) => {
          if (data) {
            resetForm();
            Swal.fire({
              text: "You have successfully Update your Number!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "user-number" });
            });
          }
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
            submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      getNumberList,
      formData,
    };
  },
});
